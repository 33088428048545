




































































































































































































































.order {
  @apply flex;
  @apply justify-between;
  @apply items-center;
  @apply text-marketplace-blue;
  @apply mt-8;
  &-text {
    @apply text-11;
    @apply font-normal;
  }
}
